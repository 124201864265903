import useSWR from "swr";
import { URL_API } from "../../../@richbeauty/services/endpoint";
import jwtAxios from "../../../@richbeauty/services/auth/jwt-auth/jwt-api";

const getAllProducts = async () => {
  const { data } = await jwtAxios.get(URL_API.CMS_PRODUCTS.GET_PRODUCTS, {
    params: {
      page: 1,
      take: 1000,
    },
  });
  return data;
};
const useGetAllProducts = () => {
  return useSWR(
    { url: URL_API.CMS_PRODUCTS.GET_PRODUCTS, tags: ["#products"] },
    getAllProducts
  );
};
export default useGetAllProducts;
