import { Tag, Typography } from "antd";

const { Text } = Typography;

const AppOptionLabel = ({ name, tags }) => (
  <div
    style={{
      width: "100%",
      display: "flex",
      alignItems: "center",
      gap: "20px",
      justifyContent: "space-between",
    }}
  >
    {name}
    <div
      style={{
        display: "flex",
        gap: "5px",
        maxWidth: "300px",
        flexWrap: "wrap",
        justifyContent: "end",
      }}
    >
      {tags.map(
        (tag, index) =>
          tag?.value && (
            <Tag
              icon={tag?.label ? `${tag.label}: ` : undefined}
              key={index}
              color="processing"
            >
              <Text
                ellipsis={{
                  tooltip: true,
                }}
                style={{ color: "#1a7aff" }}
              >
                {tag.value}
              </Text>
            </Tag>
          )
      )}
    </div>
  </div>
);

export default AppOptionLabel;
