import {
  getCountries,
  getCountryCallingCode,
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import en from "react-phone-number-input/locale/en";
import { AutoComplete, Col, Form, Select, Space } from "antd";
import PhoneInput from "react-phone-number-input/input";

const CountrySelect = ({ value, onChange, labels, ...rest }) => {
  const countries = useMemo(() => getCountries(), []);
  const options = useMemo(
    () =>
      countries.map((country) => {
        const Flag = flags[country];
        return {
          value: country,
          label: (
            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
              <Flag style={{ width: 30, height: 30 }} />
              <span>{labels[country]}</span>
              <span>+{getCountryCallingCode(country)}</span>
            </div>
          ),
          selectedLabel: (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                gap: 10,
              }}
            >
              <Flag style={{ width: 25, height: 25 }} />
            </div>
          ),
        };
      }),
    [countries, labels]
  );
  return (
    <Select
      {...rest}
      value={value}
      options={options}
      style={{
        width: 100,
        float: "left",
      }}
      showSearch
      filterOption={(input, option) =>
        (labels[option?.value] ?? "")
          .toLowerCase()
          .includes(input.toLowerCase())
      }
      dropdownMatchSelectWidth={320}
      optionLabelProp="selectedLabel"
      optionFilterProp="children"
      onChange={onChange}
    />
  );
};
const CustomPhoneInput = forwardRef(
  (
    {
      options = [],
      onChange,
      value,
      selectedCountry: country,
      changeCountry,
      placeholder,
    },
    ref
  ) => {
    const inputRef = useRef(null);
    useImperativeHandle(
      ref,
      () => {
        return inputRef.current;
      },
      []
    );
    const formattedValue = useMemo(() => {
      if (!country) return value;
      const countryCode = getCountryCallingCode(country);
      if (value.length <= `+${countryCode}`.length) return `+${countryCode}`;
      return value;
    }, [value, country]);
    return (
      <>
        <AutoComplete
          options={options}
          value={formattedValue}
          placeholder={placeholder}
          onChange={(value) => {
            const countryCode = getCountryCallingCode(country);
            let inputValue = value;
            const trimValue = value.replaceAll(/\s/g, "");
            if (trimValue.length <= `+${countryCode}`.length)
              inputValue = undefined;
            const input = inputRef.current;
            void Object.getOwnPropertyDescriptor(
              window.HTMLInputElement.prototype,
              "value"
            ).set.call(input, inputValue);
            const event = new Event("input", { bubbles: true });
            void input.dispatchEvent(event);
            const phoneNumber = value ? parsePhoneNumber(value) : {};
            if (phoneNumber?.country) void changeCountry(phoneNumber.country);
          }}
        />
        <input
          ref={inputRef}
          onChange={onChange}
          style={{
            display: "none",
          }}
        />
      </>
    );
  }
);

const CustomFormItemInput = ({ value = "", onChange, inputConfig }) => {
  const [country, setCountry] = useState();
  useEffect(() => {
    setCountry((prev) => {
      const initialCountry = value
        ? parsePhoneNumber(value)?.country ?? "US"
        : "US";
      return !prev ? initialCountry : prev;
    });
  }, [value, onChange]);
  return (
    <Space.Compact style={{ width: "100%" }}>
      <CountrySelect
        labels={en}
        value={country}
        onChange={(value) => {
          setCountry(value);
          onChange(undefined);
        }}
      />
      <PhoneInput
        international
        inputComponent={CustomPhoneInput}
        onChange={(value) => {
          onChange(value);
        }}
        value={value}
        changeCountry={setCountry}
        selectedCountry={country}
        {...inputConfig}
      />
    </Space.Compact>
  );
};
const PhoneNumberInputItem = ({ inputConfig, formItemConfig, fieldCol }) => {
  return (
    <Col sm={fieldCol ?? 24}>
      <Form.Item
        labelAlign="left"
        labelCol={{ span: 8 }}
        {...formItemConfig}
        rules={[
          {
            validator(_, value) {
              if (value && !isPossiblePhoneNumber(value)) {
                return Promise.reject(new Error("Phone number not valid!"));
              }
              return Promise.resolve();
            },
          },
          ...(formItemConfig?.rules ?? []),
        ]}
      >
        <CustomFormItemInput inputConfig={inputConfig} />
      </Form.Item>
    </Col>
  );
};

export default PhoneNumberInputItem;
