import { Dropdown, Layout, Menu, Typography } from "antd";
import "./index.style.less";
import AppLogo from "../components/AppLogo";
import AppLanguageSwitcher from "../../AppLanguageSwitcher";
import AppHeaderMessages from "../../AppHeaderMessages";
import AppNotifications from "../../AppNotifications";
import PropTypes from "prop-types";
import { FiMoreVertical } from "react-icons/fi";
import { AiOutlineMenu } from "react-icons/ai";
import { useAuthUser } from "../../../utility/AuthHooks";

const { Paragraph } = Typography;
const AppHeader = ({ isCollapsed, onToggleSidebar }) => {
  const { Header } = Layout;
  const { user: { linkedAgent } = {} } = useAuthUser();
  // const { Search } = Input;
  // const { messages } = useIntl();
  const menuMobile = (
    <Menu>
      <AppHeaderMessages />
      <AppNotifications />
      <AppLanguageSwitcher />
    </Menu>
  );

  // const getReferralLinkButton = (
  //   <Button type="default">Get referral link</Button>
  // );

  return (
    <Header className="app-header">
      <a className="trigger" onClick={() => onToggleSidebar(!isCollapsed)}>
        <AiOutlineMenu />
      </a>
      <AppLogo />
      {/*<Search*/}
      {/*	className="header-search"*/}
      {/*	placeholder={messages["common.searchHere"]}*/}
      {/*/>*/}
      <div
        style={{
          marginLeft: "auto",
          marginRight: "30px",
        }}
        className="app-header-sectionDesktop"
      >
        {linkedAgent && (
          <Paragraph
            style={{
              marginBottom: 0,
            }}
            copyable={{
              text: `https://${process.env.REACT_APP_WEBSITE_DOMAIN}/?agent-code=${linkedAgent?.code}`,
              // icon: [getReferralLinkButton, getReferralLinkButton],
              tooltips: ["Copy referral link", "Referral link copied"],
            }}
          >
            {linkedAgent?.code}
          </Paragraph>
        )}
        <AppLanguageSwitcher />
        {/*<AppHeaderMessages />*/}
        {/*<AppNotifications />*/}
      </div>
      <div className="app-header-section-mobile">
        <Dropdown overlay={menuMobile} trigger={["click"]}>
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <FiMoreVertical />
          </a>
        </Dropdown>
      </div>
    </Header>
  );
};

export default AppHeader;

AppHeader.propTypes = {
  onToggleSidebar: PropTypes.func,
  isCollapsed: PropTypes.bool,
};
