import { DeleteOutlined } from "@ant-design/icons";
import { revalidateDealNotes } from "@richbeauty/utility/helper/cacheRevalidator";
import IntlMessages from "@richbeauty/utility/IntlMessages";
import { convertDateTime } from "@richbeauty/utility/Utils";
import {
  Button,
  Col,
  Empty,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Row,
  Space,
  Steps,
} from "antd";
import useCreateDealNotes from "hooks/apis/deals/useCreateDealNote";
import useDeleteNotes from "hooks/apis/deals/useDeleteNote";
import useGetDealNote from "hooks/apis/deals/useGetDealNote";
import { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { useIntl } from "react-intl";
import { PageDefault, TakeDefault } from "shared/constants/AppConst";
import toast from "react-hot-toast";
const { TextArea } = Input;

const ViewDealNotesModal = forwardRef(({ id }, ref) => {
  const { messages } = useIntl();
  const [isVisible, setIsVisible] = useState(false);
  const openModal = useCallback(() => setIsVisible(true), [setIsVisible]);
  const closeModal = useCallback(() => setIsVisible(false), [setIsVisible]);
  useImperativeHandle(ref, () => ({ openModal, closeModal }), [
    openModal,
    closeModal,
  ]);
  const [params, setParams] = useState({
    page: PageDefault,
    take: TakeDefault,
  });
  const { data } = useGetDealNote(id, params);
  const { trigger: triggerCreate, isMutating: isMutatingCreate } =
    useCreateDealNotes();
  const { trigger: triggerDelete } = useDeleteNotes();
  const [newNote, setNewNote] = useState("");

  const onCreateNote = () => {
    triggerCreate(
      { dealId: id, formData: { note: newNote } },
      {
        onSuccess: async () => {
          setNewNote("");
          revalidateDealNotes(id);
        },
        onError: (error) =>
          toast.error(error?.message || messages["app.users.createNote.error"]),
      }
    );
  };

  const onDeleteNote = (noteId) => {
    triggerDelete(
      { dealId: id, noteId },
      {
        onSuccess: async () => revalidateDealNotes(id),
        onError: (error) =>
          toast.error(error?.message || messages["app.users.deleteNote.error"]),
      }
    );
  };

  return (
    <Modal
      centered
      open={isVisible}
      onCancel={closeModal}
      footer={null}
      width={1000}
      title={
        <>
          <IntlMessages id="app.deals.notes" />
        </>
      }
    >
      <Row gutter={40}>
        <Col span={12}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <TextArea
              value={newNote}
              onChange={(e) => setNewNote(e.target.value)}
              placeholder="New note..."
            />
            <Button
              type="primary"
              disabled={!newNote}
              loading={isMutatingCreate}
              onClick={onCreateNote}
            >
              <IntlMessages id="app.create" />
            </Button>
          </Space>
        </Col>

        <Col
          span={12}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Space>
            {data?.data?.length ? (
              <Steps
                progressDot
                current={data?.data?.length}
                direction="vertical"
                items={data?.data?.map(
                  ({ createdUser, note, id, updatedAt, createdAt }) => {
                    return {
                      title: (
                        <p style={{ whiteSpace: "pre-wrap", margin: 0 }}>
                          {note}
                        </p>
                      ),
                      description: `${
                        createdUser?.username
                      } | ${convertDateTime(updatedAt ?? createdAt)}`,
                      subTitle: (
                        <Popconfirm
                          title="Are you sure to delete this notes?"
                          onConfirm={() => onDeleteNote(id)}
                          okText="Yes"
                          cancelText="No"
                          zIndex={9999}
                        >
                          <Button
                            danger
                            size="small"
                            style={{ margin: 8 }}
                            icon={<DeleteOutlined />}
                          />
                        </Popconfirm>
                      ),
                    };
                  }
                )}
              />
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </Space>

          {data?.meta?.totalCount > TakeDefault && (
            <Pagination
              current={params.page}
              style={{ alignSelf: "flex-end" }}
              onChange={(page, pageSize) => {
                setParams((prev) => {
                  return {
                    ...prev,
                    page: page,
                    take: pageSize,
                  };
                });
              }}
              total={data?.meta?.totalCount}
              showTotal={(total, range) => {
                return (
                  <p>
                    {range[0]}-{range[1]} {messages["common.on"]} {total}{" "}
                    records
                  </p>
                );
              }}
              showSizeChanger
            />
          )}
        </Col>
      </Row>
    </Modal>
  );
});

export default ViewDealNotesModal;
