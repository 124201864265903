import jwtAxios from "@richbeauty/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@richbeauty/services/endpoint";
import useSWRMutation from "swr/mutation";
const deleteDeals = async (ids) =>
  await jwtAxios.delete(URL_API.CMS_DEALS, { data: { ids } });

const useDeleteDeals = () => {
  const fetcher = (_url, { arg }) => deleteDeals(arg);
  return useSWRMutation(URL_API.CMS_DEALS, fetcher);
};

export default useDeleteDeals;
