import { forwardRef, useCallback } from "react";
import { toast } from "react-hot-toast";
import IntlMessages from "../../../../@richbeauty/utility/IntlMessages";
import AppFormModal from "../../../../@richbeauty/core/AppFormModal";
import { revalidateDeals } from "../../../../@richbeauty/utility/helper/cacheRevalidator";
import useCreateOrEditDealFieldConfig from "./useCreateOrEditDealFieldConfig";
import usePostDeal from "hooks/apis/deals/usePostDeal";
import { DEAL_STATUS } from "shared/constants/AppConst";

const CreateDealModal = forwardRef((_, ref) => {
  const { trigger, isMutating } = usePostDeal();
  const createOrEditDealFieldConfig = useCreateOrEditDealFieldConfig({
    isEdit: false,
  });

  const onHandleCreateCoupon = useCallback(
    (formData) => {
      let formDataClone = { ...formData };
      if (!formDataClone?.status) {
        formDataClone = { ...formDataClone, status: DEAL_STATUS[0].value };
      }
      trigger(formDataClone, {
        onError: (error) => toast.error(error?.message),
        onSuccess: () => {
          revalidateDeals();
          ref.current?.closeModal();
        },
      });
    },
    [trigger, ref]
  );
  return (
    <AppFormModal
      ref={ref}
      title={<IntlMessages id="app.deals.createDeal" />}
      onFinish={onHandleCreateCoupon}
      isSubmitting={isMutating}
      fieldsConfig={createOrEditDealFieldConfig}
    />
  );
});

export default CreateDealModal;
