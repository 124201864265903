import { useMemo } from "react";
import { Empty, Image, Select, Spin, Tag, Typography } from "antd";
import { useIntl } from "react-intl";
import { filterByText, findProductImages } from "../../utility/Utils";
import ImageLoadFail from "../../../assets/images/ImageLoadFail.png";
import NoImage from "../../../assets/images/NoImage.jpg";
import useGetAllProducts from "../../../hooks/apis/products/useGetAllProducts";

const { Text } = Typography;
const { Option } = Select;

export const SelectProductOption = ({
  isSpecialProduct,
  thumbnail,
  name,
  sku,
}) => {
  return (
    <div
      style={{
        display: "flex",
        gap: 10,
        alignItems: "center",
      }}
    >
      <Image
        width={50}
        height={50}
        src={thumbnail ?? NoImage}
        preview={false}
        fallback={ImageLoadFail}
      />
      <Text
        rows={1}
        ellipsis={{
          tooltip: true,
        }}
        style={{
          maxWidth: isSpecialProduct ? "200px" : "250px",
        }}
      >
        {name}
      </Text>
      <Tag color="processing">{sku}</Tag>
      {isSpecialProduct && <Tag color="success">Special</Tag>}
    </div>
  );
};

const InfinitySelectProduct = ({ onChange, value }) => {
  const { messages } = useIntl();
  const { data = [], isLoading } = useGetAllProducts();
  const options = useMemo(
    () =>
      data.map(({ id, name, productImages, isSpecialProduct, sku }) => {
        const [thumbnail] = findProductImages({
          productImages,
          isFindThumb: true,
        });
        return {
          id,
          sku,
          name,
          thumbnail,
          isSpecialProduct,
        };
      }),
    [data]
  );
  const handleOnSelect = (selectedId) => {
    const selectedProduct = data.find(({ id }) => id === selectedId);
    onChange?.(selectedProduct);
  };
  const handleOnClear = () => {
    onChange?.(null);
  };
  return (
    <Select
      placeholder={messages["app.orders.selectProducts"]}
      onSelect={handleOnSelect}
      dropdownMatchSelectWidth={400}
      filterOption={(input, option) =>
        filterByText({
          value: option?.name,
          input,
        }) || filterByText({ value: option?.sku, input })
      }
      showSearch
      allowClear={true}
      notFoundContent={isLoading ? <Spin size="small" /> : <Empty />}
      optionLabelProp="label"
      value={value}
      onClear={handleOnClear}
      style={{
        width: "100%",
      }}
    >
      {options.map(({ id, name, thumbnail, isSpecialProduct, sku }) => {
        return (
          <Option key={id} value={id} label={name} sku={sku} name={name}>
            <SelectProductOption
              isSpecialProduct={isSpecialProduct}
              thumbnail={thumbnail}
              name={name}
              sku={sku}
            />
          </Option>
        );
      })}
    </Select>
  );
};

export default InfinitySelectProduct;
