import React, { useCallback, useMemo, useState } from "react";
import { Col, Modal, Row, Spin, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const defaultOpt = {
  title: "Confirmation",
  content: "Are you sure to proceed?",
  okText: "Yes",
  cancelText: "Cancel",
  icon: null,
};

const centerItems = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: 0,
  marginBottom: 0,
};

const buttonStyle = {
  padding: 8,
  overflow: "hidden",
  ...centerItems,
};

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const useAppConfirmModal = (zIndex) => {
  const [input, setInput] = useState(defaultOpt);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const openModal = (
    /** @type {{ title: string; content: string; okText: string; cancelText: string; onOk?: () => void, onCancel?: () => void }} */ data
  ) => {
    setInput((prev) => ({ ...prev, ...data }));
    setIsVisible(true);
  };
  const closeModal = () => {
    setIsVisible(false);
    setInput(defaultOpt);
  };

  const handleOk = useCallback(async () => {
    setLoading(true);
    try {
      return await input.onOk();
    } catch (error) {
      throw new Error(error);
    } finally {
      setLoading(false);
      closeModal();
    }
  }, [input]);

  const contextHolder = useMemo(() => {
    return (
      <Modal
        zIndex={zIndex}
        open={isVisible}
        closable={false}
        footer={null}
        width={350}
        destroyOnClose
      >
        <Row style={{ backgroundColor: "#fff", borderRadius: 8, margin: -6 }}>
          <Col span={24} style={{ ...centerItems, marginBottom: 8 }}>
            {input?.icon ?? input?.icon}
            <Typography.Text
              style={{
                fontSize: 20,
                fontWeight: 600,
                marginTop: 4,
                padding: 0,
              }}
            >
              {input?.title}
            </Typography.Text>
          </Col>
          <Col span={24} style={{ ...centerItems, marginBottom: 16 }}>
            <Typography
              style={{ lineHeight: "24px" }}
              dangerouslySetInnerHTML={{
                __html: `${input?.content}`,
              }}
            />
          </Col>
          <Col span={24} style={{ marginBottom: 0 }}>
            <Row style={{ ...centerItems }}>
              {/** === Cancel Button === */}
              <Col
                span={12}
                onClick={closeModal}
                style={{
                  backgroundColor: "#FFFFFF",
                  ...centerItems,
                  borderRadius: 8,
                  cursor: "pointer",
                }}
              >
                <Col span={24} style={{ ...buttonStyle }}>
                  <Typography.Text style={{ color: "#0A8FDC" }}>
                    {input?.cancelText}
                  </Typography.Text>
                </Col>
              </Col>
              {/** === Submit Button === */}
              <Col
                span={12}
                onClick={handleOk}
                style={{
                  backgroundColor: "#0A8FDC",
                  ...centerItems,
                  borderRadius: 8,
                  cursor: "pointer",
                }}
              >
                <Col
                  span={24}
                  style={{ ...buttonStyle, opacity: isLoading ? 0.7 : 1 }}
                >
                  <Typography.Text style={{ color: "#FFFFFF" }}>
                    {input?.okText}
                  </Typography.Text>
                </Col>
                <Spin
                  indicator={antIcon}
                  spinning={isLoading}
                  style={{
                    ...centerItems,
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    backgroundColor: "#FFFFFF",
                    overflow: "hidden",
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    );
  }, [handleOk, isVisible, input, isLoading, zIndex]);
  return {
    openModal,
    closeModal,
    contextHolder,
  };
};

export default useAppConfirmModal;
