export const URL_API = {
  AUTHENTICATION: {
    LOGIN: "authentication/login",
  },
  UPDATE_PROFILE: "cms-users",
  USER_INFORMATION: "cms-users/id",
  ROLE: {
    POST_ROLE: "cms-roles",
    GET_ALL_PERMISSIONS: "cms-roles/get-permissions",
    GET_ALL_ROLES: "cms-roles/get-roles",
    GET_ROLES: "cms-roles/get-roles",
    GET_ROLE: "cms-roles/id",
    PATCH_ROLE: "cms-roles",
    DELETE_ROLES: "cms-roles/delete-roles",
    GET_USERS_BY_ROLE_ID: "role/get-users-by-roleId",
  },
  CMS_SETTINGS: {
    ADD_SETTINGS: "cms-settings/add-settings",
    GET_SETTINGS: "cms-settings/get-settings",
    UPLOAD_LOGO: "cms-settings/upload-logo",
    UPLOAD_FAVICON: "cms-settings/upload-favicon",
  },
  CMS_COUPONS: {
    POST_COUPON: "cms-coupons",
    GET_COUPONS: "cms-coupons/get-coupons",
    GET_ALL_COUPONS: "cms-coupons/get-coupons-no-pagination",
    GET_COUPON: "cms-coupons/id",
    PATCH_COUPON: "cms-coupons",
    DELETE_COUPONS: "cms-coupons/delete-coupons",
    VERIFY_COUPON: "coupons/verify-coupon",
  },
  CMS_BLOGS: {
    POST_BLOG: "cms-blogs",
    GET_BLOGS: "cms-blogs/get-blogs",
    GET_BLOG: "cms-blogs/id",
    PATCH_BLOG: "cms-blogs",
    DELETE_BLOGS: "cms-blogs/delete-blogs",
  },
  CMS_BANNERS: {
    POST_BANNER: "cms-banners",
    GET_BANNERS: "cms-banners/get-banners",
    GET_BANNER: "cms-banners/id",
    PATCH_BANNER: "cms-banners",
    DELETE_BANNERS: "cms-banners/delete-banners",
  },
  CMS_ZIPCODE_FEES: {
    POST_ZIPCODE_FEE: "cms-zipcodefees",
    GET_ZIPCODE_FEES: "cms-zipcodefees/get-zipcodefees",
    GET_ZIPCODE_FEE: "cms-zipcodefees/id",
    PATCH_ZIPCODE_FEE: "cms-zipcodefees",
    DELETE_ZIPCODE_FEES: "cms-zipcodefees/delete-zipcodefees",
  },
  CMS_CATEGORIES: {
    POST_CATEGORY: "cms-categories",
    GET_CATEGORIES: "cms-categories/get-categories",
    GET_ALL_CATEGORIES: "cms-categories/get-categories-no-pagination",
    GET_CATEGORY: "cms-categories/id",
    PATCH_CATEGORY: "cms-categories",
    DELETE_CATEGORIES: "cms-categories/delete-categories",
  },
  CMS_USERS: {
    POST_USER: "cms-users",
    GET_USERS: "cms-users/get-users",
    GET_ALL_USERS: "cms-users/get-users",
    GET_USER: "cms-users/id",
    PATCH_USER: "cms-users",
    DELETE_USERS: "cms-users/delete-users",
    BLOCK_USERS: "cms-users/block-user",
    UNBLOCK_USERS: "cms-users/unblock-user",
    VERIFY_PHONE_USERS: "cms-users/send-verify-phone",
    VERIFY_MAIL_USERS: "cms-users/send-verify-mail",
    INTRODUCE_SALE: "cms-users/send-introduce",
    SET_PASSWORD: "cms-users/set-password",
    POST_TOP_UP: "cms-users/topup",
    GET_TOP_UPS: "cms-users/topup",
    GET_CREDIT: "cms-users/{userId}/credit",
    IMPORT: "cms-users/import-users",
    DOWNLOAD_TEMPLATE: "cms-users/import-users/template",
  },
  CMS_USER_SHIPPINGS: {
    POST_USER_SHIPPING: "cms-user-shippings",
    GET_USER_SHIPPINGS: "cms-user-shippings",
    FIND_ALL_BY_USER: "cms-user-shippings/find-all-by-user",
    GET_USER_SHIPPING: "cms-user-shippings",
    PATCH_USER_SHIPPING: "cms-user-shippings",
    DELETE_USER_SHIPPINGS: "cms-user-shippings/delete-user-shippings",
  },
  CMS_USER_BILLINGS: {
    POST_USER_BILLING: "cms-user-billings",
    GET_USER_BILLINGS: "cms-user-billings",
    FIND_ALL_BY_USER: "cms-user-billings/find-all-by-user",
    GET_USER_BILLING: "cms-user-billings",
    PATCH_USER_BILLING: "cms-user-billings",
    DELETE_USER_BILLINGS: "cms-user-billings/delete-user-billings",
  },
  CMS_USER_NOTES: {
    POST_USER_NOTES: "post-cms-user-notes",
    GET_USER_NOTES: "get-cms-user-notes",
    PATCH_USER_NOTES: "patch-cms-user-notes",
    DELETE_USER_NOTES: "delete-cms-user-notes",
  },
  CMS_SHOPPING_CARTS: {
    GET_SHOPPING_CARTS: "cms-shopping-carts",
    GET_SHOPPING_CART: "cms-shopping-carts",
  },
  CMS_AGENTS: {
    POST_AGENT: "cms-agents",
    GET_AGENTS: "cms-agents/get-agents",
    GET_ALL_AGENTS: "cms-agents/get-agents",
    GET_AGENT: "cms-agents/id",
    PATCH_AGENT: "cms-agents",
    DELETE_AGENTS: "cms-agents/delete-agents",
    GET_ORDERS: "cms-agents/get-orders",
    GET_USERS: "cms-agents/get-users",
  },
  CMS_COMPANY_AGENTS: {
    GET_ALL_COMPANY_AGENTS:
      "cms-company-agents/get-company-agents-no-pagination",
    POST_COMPANY_AGENT: "cms-company-agents",
    GET_COMPANY_AGENTS: "cms-company-agents/get-company-agents",
    GET_COMPANY_AGENT: "cms-company-agents/id",
    PATCH_COMPANY_AGENT: "cms-company-agents",
    DELETE_COMPANY_AGENTS: "cms-company-agents/delete-company-agents",
    REMOVE_AGENTS: "cms-company-agents/remove-agents",
    GET_AGENTS: "cms-company-agents/get-agents",
    GET_ORDERS: "cms-company-agents/get-orders",
  },
  CMS_TAGS: {
    POST_TAG: "cms-tags",
    GET_TAGS: "cms-tags/get-tags",
    GET_ALL_TAGS: "cms-tags/get-tags",
    GET_TAG: "cms-tags/id",
    PATCH_TAG: "cms-tags",
    DELETE_TAGS: "cms-tags/delete-tags",
  },
  CMS_QUOTES: {
    GET_QUOTES: "cms-quotes/get-quotes",
    GET_QUOTE: "cms-quotes/id",
    PATCH_QUOTE: "cms-quotes",
    DELETE_QUOTES: "cms-quotes/delete-quotes",
  },
  CMS_EMAIL_NEWSLETTERS: {
    GET_EMAIL_NEWSLETTERS: "cms-newsletters/get-newsletters",
    GET_EMAIL_NEWSLETTER: "cms-newsletters/id",
  },
  CMS_PRODUCTS: {
    POST_PRODUCT: "cms-products",
    POST_UPDATE_STOCKS: "cms-products/update-stocks",
    GET_PRODUCTS: "cms-products/get-products",
    GET_PRODUCT: "cms-products/id",
    PATCH_ADD_FIELD: "cms-products/add-field",
    PATCH_UPDATE_FIELD: "cms-products/update-field",
    DELETE_FIELDS: "cms-products/delete-fields",
    PATCH_UPDATE_PRODUCT: "cms-products/update-product",
    DELETE_PRODUCTS: "cms-products/delete-products",
    UPDATE_STOCK_IMAGE: "cms-products/update-stock-image",
  },
  CMS_REVIEWS: {
    POST_REVIEW: "cms-reviews",
    GET_REVIEWS: "cms-reviews/get-reviews",
    GET_REVIEW: "cms-reviews/id",
    PATCH_REVIEW: "cms-reviews",
    DELETE_REVIEWS: "cms-reviews/delete-reviews",
  },
  CMS_ORDERS: {
    POST_ORDER: "cms-orders",
    GET_ORDERS: "cms-orders",
    GET_ALL_ORDERS: "cms-orders",
    GET_ORDER: "cms-orders",
    PATCH_ORDER: "cms-orders",
    PRINT_ORDER: "cms-orders/print-pdf",
    UPDATE_TRACKING_NO: "cms-orders/update-tracking-no",
    UPDATE_ORDER_IMAGES: "cms-orders/update-order-images",
    CALCULATE_INVOICE: "cms-orders/calculate-invoice",
    SEND_INVOICE: "cms-orders/send-invoice",
    UPDATE_ORDER_DETAILS: "cms-orders/update-detail",
    GET_ORDER_PAYMENT: "cms-orders/order-payment",
    CHECK_LAST_OFFER: "cms-orders/get-last-offer",
    CHECK_MULTIPLE_LAST_OFFER: "cms-orders/get-multiple-last-offer",
    GET_DEPOSITS: "cms-orders/deposits",
    POST_DEPOSIT: "cms-orders/deposits",
    PATCH_DEPOSIT: "cms-orders/deposits",
    PATCH_DEPOSIT_PAYMENT_TYPE: "cms-orders/deposits/edit",
    DELETE_DEPOSIT: "cms-orders/deposits",
    GET_DEPOSIT: "cms-orders/order-deposits",
    SEND_MAIL_SHIPPING: "cms-orders/send-mail-shipping",
    EXPORT_ORDERS: "cms-orders/export/excel",
  },
  CMS_SHIPPING_RANGES: {
    POST_SHIPPING_RANGE: "cms-shipping-ranges",
    GET_SHIPPING_RANGES: "cms-shipping-ranges/get-shipping-ranges",
    GET_ALL_SHIPPING_RANGES: "cms-shipping-ranges/get-shipping-ranges",
    GET_SHIPPING_RANGE: "cms-shipping-ranges/id",
    PATCH_SHIPPING_RANGE: "cms-shipping-ranges/update-shipping-ranges",
    DELETE_SHIPPING_RANGES: "cms-shipping-ranges/delete-shipping-ranges",
  },
  CMS_STATISTICS: {
    GET_STATISTICS: "cms-statistics/get-statistics",
  },
  S3: {
    PUT_FILE_TO_S3: "PUT_FILE_TO_S3",
  },
  CMS_EXPENSES: {
    POST_EXPENSE_TYPE: "cms-expenses/create-expense-type",
    GET_EXPENSE_TYPES: "cms-expenses/get-all-expense-types",
    GET_ALL_EXPENSE_TYPES: "cms-expenses/get-all-expense-types",
    GET_EXPENSE_TYPE: "cms-expenses/get-expense-type-by-id",
    PATCH_EXPENSE_TYPE: "cms-expenses/update-expense-type",
    DELETE_EXPENSE_TYPES: "cms-expenses/delete-expense-type",
    POST_EXPENSE: "cms-expenses/create-expense",
    GET_ALL_EXPENSES: "cms-expenses/get-all-expenses",
    GET_EXPENSES: "cms-expenses/get-all-expenses",
    GET_EXPENSE: "cms-expenses/get-expense-by-id",
    PATCH_EXPENSE: "cms-expenses/update-expense",
    DELETE_EXPENSES: "cms-expenses/delete-expense",
  },
  CMS_DELIVERIES: {
    POST_DELIVERY: "cms-orders/delivery",
    GET_ALL_DELIVERIES: "cms-orders/deliveries",
    GET_DELIVERIES: "cms-orders/deliveries",
    GET_DELIVERY: "cms-orders/delivery",
    PATCH_DELIVERY: "cms-orders/delivery",
    DELETE_DELIVERY: "cms-orders/delivery",
    PRINT_DELIVERY: "cms-orders/delivery/print-pdf",
    PRINT_PALLETS: "cms-orders/delivery/pallets/print-pdf",
    TRACKING: "cms-orders/delivery/tracking",
  },
  CMS_LEADS: "cms-leads",
  CMS_DEALS: "cms-deals",
  CMS_SEO_CAMPAIGNS: "cms-seo-campaigns",
};
