import jwtAxios from "@richbeauty/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@richbeauty/services/endpoint";
import useSWRMutation from "swr/mutation";

const createDealNotes = async ({ dealId, formData }) =>
  jwtAxios.post(`${URL_API.CMS_DEALS}/${dealId}/note`, formData);

const useCreateDealNotes = () => {
  const fetcher = (_url, { arg }) => createDealNotes(arg);
  return useSWRMutation(URL_API.CMS_DEALS, fetcher);
};

export default useCreateDealNotes;
