import jwtAxios from "@richbeauty/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@richbeauty/services/endpoint";
import useSWR from "swr";
const getDealNote = async (dealId, params) =>
  jwtAxios.get(`${URL_API.CMS_DEALS}/${dealId}/note`, { params });

const useGetDealNote = (dealId, params) => {
  const key = {
    url: URL_API.CMS_DEALS,
    dealId,
    params,
    tags: [`#dealNotes:${dealId}`],
  };
  const fetcher = () => getDealNote(dealId, params);
  return useSWR(key, fetcher);
};
export default useGetDealNote;
