import { StarFilled } from "@ant-design/icons";
import React from "react";
import { Rate } from "antd";

const StarItem = ({ value, isLongDisplay = false }) => {
  return isLongDisplay ? (
    <Rate
      disabled
      value={value}
      character={
        <StarFilled
          style={{
            width: 15,
            height: 15,
          }}
        />
      }
    />
  ) : (
    <>
      <span>{parseFloat(value?.toFixed(2))}</span>{" "}
      <StarFilled
        style={{
          color: "#fadb13",
        }}
      />
    </>
  );
};

export default StarItem;
