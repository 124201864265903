import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { AppRowContainer } from "../../index";
import IntlMessages from "../../utility/IntlMessages";
import { Button, Form, Modal, Tabs } from "antd";
import FieldItem from "./FieldItem";
import { APP_FORM_MODAL_TYPE } from "../../../shared/constants/AppEnums";
import TabForm from "./TabForm";
import FormItemListSkeleton from "../AppSkeleton/FormItemListSkeleton";
import { AppFormModalDataContext } from "./AppFormModalDataContext";
import "./index.style.less";

// const safeStructuredClone = (data) => {
//   // structured cloned on moment object throws error
//   const [cloneableObject, unCloneableObject] = Object.entries(data).reduce(
//     ([cloneableObject, unCloneableObject], [key, value]) => {
//       if (value?._isAMomentObject)
//         return [
//           cloneableObject,
//           {
//             ...unCloneableObject,
//             [key]: value,
//           },
//         ];
//       return [{ ...cloneableObject, [key]: value }, unCloneableObject];
//     },
//     [{}, {}]
//   );
//   return {
//     ...structuredClone(cloneableObject),
//     ...unCloneableObject,
//   };
// };

const getTabIndexFromFieldName = ({ fieldsConfig, fieldName }) => {
  return fieldsConfig?.findIndex(({ fieldsConfig }) =>
    fieldsConfig?.some(
      ({ name, formItemConfig }) => (formItemConfig?.name ?? name) === fieldName
    )
  );
};

const AppFormModal = (
  {
    fieldsConfig,
    onFinish,
    title,
    data,
    isSubmitting,
    width,
    isDisabled,
    isLoading,
    onReset,
    type = APP_FORM_MODAL_TYPE.DEFAULT,
  },
  ref
) => {
  const [form] = Form.useForm();
  const dataRef = useRef();
  const [activeKey, setActiveKey] = useState(0);
  const [visitedTabKeys, setVisitedTabKeys] = useState([0]);
  const needVisitedTabKeys = useMemo(() => {
    const isFormEdit = Boolean(data);
    return type === APP_FORM_MODAL_TYPE.TAB && !isFormEdit
      ? fieldsConfig.reduce((acc, { isNeedVisit }, index) => {
          return isNeedVisit ? [...acc, index] : acc;
        }, [])
      : [];
  }, [data, fieldsConfig, type]);
  useEffect(() => {
    // const clonedData = safeStructuredClone(data);
    form.setFieldsValue(data);
    dataRef.current = data;
  }, [data, form]);
  useEffect(() => {
    setVisitedTabKeys((prev) =>
      prev.includes(activeKey) ? prev : [activeKey, ...prev]
    );
  }, [activeKey]);
  const [isVisible, setIsVisible] = useState(false);
  const openModal = useCallback(() => {
    form.setFieldsValue(dataRef.current);
    setIsVisible(true);
  }, [form, setIsVisible]);
  const closeModal = useCallback(() => {
    setIsVisible(false);
    setActiveKey(0);
    form.resetFields();
    onReset?.();
  }, [setIsVisible, form, onReset]);
  useImperativeHandle(
    ref,
    () => ({
      openModal,
      closeModal,
    }),
    [openModal, closeModal]
  );
  const fieldsName =
    type === APP_FORM_MODAL_TYPE.TAB
      ? fieldsConfig
          .flatMap(({ fieldsConfig }) => fieldsConfig)
          .map(({ formItemConfig, name }) => formItemConfig?.name ?? name)
          .filter((item) => Boolean(item))
      : [];
  const onSubmit = async () => {
    form
      .validateFields()
      .then((values) => {
        const needVisitedTabKey = needVisitedTabKeys.find(
          (key) => !visitedTabKeys.includes(key)
        );
        if (needVisitedTabKey) {
          setActiveKey(needVisitedTabKey);
        } else onFinish(values);
      })
      .catch(({ errorFields }) => {
        const firstErrorFieldName = errorFields?.[0].name?.[0];
        const firstErrorTabKey = getTabIndexFromFieldName({
          fieldsConfig,
          fieldName: firstErrorFieldName,
        });
        if (firstErrorTabKey >= 0) setActiveKey(firstErrorTabKey);
        setTimeout(
          () =>
            form.scrollToField(errorFields?.[0]?.name, {
              block: "center",
              behavior: "smooth",
            }),
          0
        );
      });
  };
  return (
    <AppFormModalDataContext.Provider
      value={{
        initialFormData: data,
        formInstance: form,
      }}
    >
      <Modal
        open={isVisible}
        onCancel={closeModal}
        footer={null}
        width={width ?? 700}
        title={title}
        maskClosable={false}
        destroyOnClose={true}
      >
        {isLoading ? (
          <FormItemListSkeleton />
        ) : (
          <Form
            onFinish={(values) => {
              onFinish(values);
            }}
            form={form}
            scrollToFirstError
            disabled={isSubmitting || isDisabled}
            // onValuesChange={(_, allValues) => console.log({ allValues })}
          >
            {type === APP_FORM_MODAL_TYPE.DEFAULT && (
              <AppRowContainer gutter={16}>
                {fieldsConfig.map((fieldConfig, index) => (
                  <FieldItem key={index} fieldConfig={fieldConfig} />
                ))}
              </AppRowContainer>
            )}
            {type === APP_FORM_MODAL_TYPE.TAB && (
              <>
                {fieldsName.map((fieldName) => (
                  <Form.Item key={fieldName} name={fieldName} noStyle />
                ))}
                <Tabs
                  popupClassName="tab-dropdown"
                  type="card"
                  activeKey={activeKey}
                  onChange={(key) => {
                    setActiveKey(key);
                  }}
                  items={fieldsConfig.map(({ label, fieldsConfig }, index) => ({
                    label,
                    key: index,
                    children: <TabForm fieldsConfig={fieldsConfig} />,
                    disabled: isSubmitting,
                  }))}
                />
              </>
            )}
            <div className="ant-modal-footer">
              <Button type="primary" ghost onClick={closeModal}>
                <IntlMessages id="common.cancel" />
              </Button>
              <Button type="primary" onClick={onSubmit} loading={isSubmitting}>
                <IntlMessages id="common.save" />
              </Button>
            </div>
          </Form>
        )}
      </Modal>
    </AppFormModalDataContext.Provider>
  );
};

export default forwardRef(AppFormModal);
