import React, { useMemo } from "react";
import { MoreOutlined } from "@ant-design/icons";
import { Dropdown, Button } from "antd";
import { filterPermissionGrantedList } from "../../utility/Utils";
import { useAuthUser } from "../../utility/AuthHooks";

const AppColumnActionOption = ({ items, zIndex }) => {
  const { user } = useAuthUser();
  const menuItems = useMemo(() => {
    const filteredGrantedItems = filterPermissionGrantedList({
      listItems: items,
      permissions: user?.permissions,
    });
    return filteredGrantedItems.map((item, index) => ({
      key: index,
      label: (
        <p
          style={{ fontSize: 14, minWidth: "100px", margin: "0" }}
          onClick={item.onClick}
        >
          {item.label}
        </p>
      ),
      disabled: item?.isDisabled,
    }));
  }, [items, user]);
  return menuItems.length > 0 ? (
    <Dropdown
      zIndex={zIndex}
      menu={{ items: menuItems }}
      trigger={["click"]}
      placement="bottomRight"
    >
      <Button
        type="circle"
        style={{
          border: "1px solid transparent",
          borderColor: "rgba(0, 0, 0, 0.12)",
        }}
      >
        <MoreOutlined />
      </Button>
    </Dropdown>
  ) : null;
};

export default AppColumnActionOption;
