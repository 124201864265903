const notificationData = [
	{
		id: "1000",
		name: "Aysha Julka",
		image: "/assets/images/avatar/A1.jpg",
		message: "commented on your wall picture.",
	},
	{
		id: "1001",
		name: "Ayra Rovishi",
		image: "/assets/images/avatar/A2.jpg",
		message: "added to their stories.",
	},
	{
		id: "1002",
		name: "Sapna Awasthi",
		image: "/assets/images/avatar/A3.jpg",
		message: "commented on your wall picture.",
	},
	{
		id: "1003",
		name: "Sami Rudri",
		image: "/assets/images/avatar/A4.jpg",
		message: "tagged you in a picture.",
	},
	{
		id: "1004",
		name: "Brian Lara",
		image: "/assets/images/avatar/A5.jpg",
		message: "marked himself safe during earth quake.",
	},
	{
		id: "1005",
		name: "Rickey Ponting",
		image: "/assets/images/avatar/A6.jpg",
		message: "commented on your wall picture.",
	},
	{
		id: "1007",
		name: "Smriti Mandhana",
		image: "/assets/images/avatar/A8.jpg",
		message: "changed her wall picture.",
	},
	{
		id: "1008",
		name: "Aysha Julka",
		image: "/assets/images/avatar/A9.jpg",
		message: "changed her wall picture.",
	},
];
export default notificationData;
