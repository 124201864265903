// @ts-nocheck
import React from "react";

const ECommerce = React.lazy(() => import("./ECommerce"));

export const dashboardConfig = [
  {
    path: "/dashboards",
    element: <ECommerce />,
  },
];
