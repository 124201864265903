import jwtAxios from "@richbeauty/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@richbeauty/services/endpoint";
import useSWRMutation from "swr/mutation";

const deleteNotes = async ({ dealId, noteId }) =>
  jwtAxios.delete(`${URL_API.CMS_DEALS}/${dealId}/note/${noteId}`);

const useDeleteNotes = () => {
  const fetcher = (_url, { arg }) => deleteNotes(arg);
  return useSWRMutation(URL_API.CMS_DEALS, fetcher);
};

export default useDeleteNotes;
