import jwtAxios from "@richbeauty/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@richbeauty/services/endpoint";
import useSWRMutation from "swr/mutation";
const updateDeal = async (id, formData) =>
  await jwtAxios.patch(`${URL_API.CMS_DEALS}/${id}`, formData);

const useUpdateDeal = () => {
  const fetcher = (_url, { arg }) => updateDeal(arg.id, arg.formData);
  return useSWRMutation(URL_API.CMS_DEALS, fetcher);
};

export default useUpdateDeal;
