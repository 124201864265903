import React from "react";
import "./shared/styles/richbeauty.less";
import {
  AppContextProvider,
  AppLayout,
  AppLocaleProvider,
  AppThemeProvider,
  AuthRoutes,
} from "./@richbeauty";
import { BrowserRouter } from "react-router-dom";
import "./@richbeauty/services/index";
import JWTAuthAuthProvider from "./@richbeauty/services/auth/jwt-auth/JWTAuthProvider";
import { SWRConfig } from "swr";
import { Toaster } from "react-hot-toast";
const swrConfig = {
  revalidateOnFocus: false,
  shouldRetryOnError: false,
  revalidateIfStale: false,
  keepPreviousData: true,
};

const App = () => (
  <AppContextProvider>
    <SWRConfig value={swrConfig}>
      <AppThemeProvider>
        <AppLocaleProvider>
          <BrowserRouter>
            <JWTAuthAuthProvider>
              <AuthRoutes>
                <AppLayout />
              </AuthRoutes>
            </JWTAuthAuthProvider>
          </BrowserRouter>
        </AppLocaleProvider>
      </AppThemeProvider>
      <Toaster />
    </SWRConfig>
  </AppContextProvider>
);

export default App;
