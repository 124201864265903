import IntlMessages from "@richbeauty/utility/IntlMessages";
import { AppInputType } from "../../../../shared/constants/AppEnums";
import { useIntl } from "react-intl";
import {
  COMMON_FORM_ITEM_RULE,
  DEAL_STATUS,
  LEAD_SOURCE,
} from "../../../../shared/constants/AppConst";
import useGetAllCampaign from "hooks/apis/seo-compaign/useGetAllCampaign";

const useCreateOrEditDealFieldConfig = ({ isEdit }) => {
  const { messages } = useIntl();
  const { data: seoCampaigns } = useGetAllCampaign();
  return [
    {
      type: AppInputType.TEXT,
      inputConfig: { placeholder: messages["app.leads.name"] },
      formItemConfig: {
        name: "name",
        label: <IntlMessages id="app.leads.name" />,
        rules: [COMMON_FORM_ITEM_RULE.REQUIRED],
      },
    },
    {
      type: AppInputType.PHONE_NUMBER_INPUT,
      inputConfig: { placeholder: messages["app.leads.phone"] },
      formItemConfig: {
        name: "phone",
        label: <IntlMessages id="app.leads.phone" />,
        rules: [COMMON_FORM_ITEM_RULE.REQUIRED],
      },
    },
    {
      type: AppInputType.TEXT,
      inputConfig: { placeholder: messages["app.leads.email"] },
      formItemConfig: {
        name: "email",
        label: <IntlMessages id="app.leads.email" />,
        rules: [COMMON_FORM_ITEM_RULE.EMAIL],
      },
    },
    {
      type: AppInputType.SELECT,
      formItemConfig: {
        name: "status",
        label: <IntlMessages id="app.deals.dealStatus" />,
      },
      inputConfig: {
        allowClear: false,
        placeholder: messages["app.deals.dealStatus"],
        options: DEAL_STATUS.map(({ value, label }) => ({
          value: value,
          label: label,
        })),
        defaultValue: !isEdit ? DEAL_STATUS[0].value : "",
      },
    },
    {
      type: AppInputType.SELECT,
      formItemConfig: {
        name: "source",
        label: <IntlMessages id="app.leads.leadSource" />,
      },
      inputConfig: {
        placeholder: messages["app.leads.leadSource"],
        options: LEAD_SOURCE.map(({ value, label }) => ({
          value: value,
          label: label,
        })),
      },
    },
    {
      type: AppInputType.SELECT,
      formItemConfig: {
        name: "seoCampaignId",
        label: <IntlMessages id="app.leads.campaign" />,
        rules: [COMMON_FORM_ITEM_RULE.REQUIRED],
      },
      inputConfig: {
        disabled: isEdit,
        placeholder: messages["app.leads.campaign"],
        options: seoCampaigns?.data?.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
      },
    },
    {
      type: AppInputType.DATE_PICKER,
      inputConfig: {
        placeholder: messages["app.deals.startDate"],
        disabled: isEdit,
      },
      formItemConfig: {
        name: "startDate",
        label: <IntlMessages id="app.deals.startDate" />,
        rules: [COMMON_FORM_ITEM_RULE.REQUIRED],
      },
    },
    {
      type: AppInputType.TEXT_AREA,
      inputConfig: {
        placeholder: messages["app.leads.description"],
      },
      formItemConfig: {
        name: "description",
        label: <IntlMessages id="app.leads.description" />,
      },
    },
  ];
};

export default useCreateOrEditDealFieldConfig;
