import React from "react";
import { Table } from "antd";
import clsx from "clsx";
import PropTypes from "prop-types";
import QueueAnim from "rc-queue-anim";
import "./index.style.less";

const AppTableContainer = (props) => {
  const { columns, data, hoverColor, className, ...rest } = props;
  return (
    <QueueAnim
      component={Table}
      type="left"
      className={clsx(
        "table-responsive",
        { hoverColor: hoverColor },
        data?.length > 0 ? "" : "empty",
        className
      )}
      columns={columns}
      dataSource={data}
      rowKey="id"
      scroll={{ x: "max-content", y: "calc(100vh - 400px)" }}
      sticky
      hoverColor
      pagination={false}
      {...rest}
    />
  );
};

export default AppTableContainer;

AppTableContainer.propTypes = {
  columns: PropTypes.any,
  data: PropTypes.array,
  className: PropTypes.string,
  pagination: PropTypes.object,
  hoverColor: PropTypes.bool,
};

AppTableContainer.defaultProps = {
  pagination: false,
};
