import { parsePhoneNumber } from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import { Typography } from "antd";

const PhoneNumberItem = ({ value }) => {
  if (!value) return null;
  const phoneNumber = parsePhoneNumber(value) ?? {};
  if (!phoneNumber?.country)
    return (
      <Typography.Text style={{ color: "#1677ff" }}>{value}</Typography.Text>
    );
  const nationalFormattedPhoneNumber = phoneNumber?.formatNational();
  const Flag = flags[phoneNumber?.country];
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 10,
      }}
    >
      <Flag
        style={{
          width: 20,
          height: 20,
        }}
      />
      <Typography.Text style={{ color: "#1677ff" }}>
        {nationalFormattedPhoneNumber}
      </Typography.Text>
    </div>
  );
};

export default PhoneNumberItem;
