import { DownOutlined } from "@ant-design/icons";
import AppAnimateGroup from "../@richbeauty/core/AppAnimateGroup";
import { Button, Checkbox, Dropdown, Form, Space } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import AppScrollbar from "../@richbeauty/core/AppScrollbar";
import "./index.style.less";
import IntlMessages from "../@richbeauty/utility/IntlMessages";
import { TABLE_ACTION_COLUMN_KEY } from "../shared/constants/AppConst";

const useAppShowHideTableColumn = ({ tableKey, tableColumns }) => {
  const [form] = Form.useForm();
  const [tableColumnDisplayConfig, setTableColumnDisplayConfig] = useState([]);
  useEffect(() => {
    const configDataFromLocalStorage = JSON.parse(
      localStorage.getItem(tableKey)
    );
    if (configDataFromLocalStorage) {
      setTableColumnDisplayConfig(configDataFromLocalStorage);
      form.setFieldsValue(configDataFromLocalStorage);
    } else {
      const tableColumnDisplayDefault = tableColumns.reduce(
        (prev, curr) => ({
          ...prev,
          [curr.key]: true,
        }),
        {}
      );
      setTableColumnDisplayConfig(tableColumnDisplayDefault);
      localStorage.setItem(tableKey, JSON.stringify(tableColumnDisplayDefault));
      form.setFieldsValue(tableColumnDisplayDefault);
    }
    // eslint-disable-next-line
  }, [form, tableKey]);
  const filteredTableColumns = useMemo(
    () =>
      tableColumns.filter(
        (column) => tableColumnDisplayConfig[column.key] === true
      ),
    [tableColumnDisplayConfig, tableColumns]
  );
  const tableColumnDisplayConfigurator = useMemo(() => {
    return (
      <Dropdown
        trigger={["click"]}
        dropdownRender={() => {
          return (
            <Form
              initialValues={tableColumnDisplayConfig}
              form={form}
              name={tableKey}
              onValuesChange={() => {
                setTableColumnDisplayConfig(form.getFieldsValue());
                localStorage.setItem(
                  tableKey,
                  JSON.stringify(form.getFieldsValue())
                );
              }}
            >
              <AppScrollbar className="config-container" scrollToTop={false}>
                <AppAnimateGroup>
                  {tableColumns?.map((item, index) => {
                    const { key, title } = item;
                    return (
                      <Form.Item key={index} name={key} valuePropName="checked">
                        <Checkbox disabled={key === TABLE_ACTION_COLUMN_KEY}>
                          {title}
                        </Checkbox>
                      </Form.Item>
                    );
                  })}
                </AppAnimateGroup>
              </AppScrollbar>
            </Form>
          );
        }}
      >
        <Button>
          <Space>
            <IntlMessages id="common.display" />
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    );
  }, [form, tableColumnDisplayConfig, tableColumns, tableKey]);
  return {
    filteredTableColumns,
    tableColumnDisplayConfigurator,
  };
};

export default useAppShowHideTableColumn;
