import { Tag } from "antd";
import IntlMessages from "../../../@richbeauty/utility/IntlMessages";
import {
  DEAL_STATUS,
  LEAD_SOURCE,
  TABLE_ACTION_COLUMN_KEY,
  UserActionColumns,
} from "shared/constants/AppConst";
import DealColumnActionOption from "./DealColumnActionOption";
import moment from "moment";
import { convertDollarString } from "@richbeauty/utility/Utils";

const useGetDealColumn = () => {
  return [
    {
      title: <IntlMessages id="app.leads.name" />,
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: <IntlMessages id="app.leads.phone" />,
      dataIndex: "phone",
      key: "phone",
      width: 150,
    },
    {
      title: <IntlMessages id="app.leads.email" />,
      dataIndex: "email",
      key: "email",
      width: 150,
    },
    {
      title: <IntlMessages id="app.deals.dealStatus" />,
      dataIndex: "status",
      key: "status",
      width: 150,
      render: (status) => {
        const { color, label } =
          DEAL_STATUS.find((item) => item.value === status) || {};
        return <Tag color={color}>{label}</Tag>;
      },
    },
    {
      title: <IntlMessages id="app.leads.campaign" />,
      dataIndex: "seoCampaign",
      key: "campaign",
      width: 150,
      render: (seoCampaign) => {
        return <Tag>{seoCampaign?.name}</Tag>;
      },
    },
    {
      title: <IntlMessages id="app.leads.leadSource" />,
      dataIndex: "source",
      key: "source",
      width: 150,
      render: (source) => {
        const { color, label } =
          LEAD_SOURCE.find((item) => item.value === source) || {};
        return <Tag color={color}>{label}</Tag>;
      },
    },
    {
      title: <IntlMessages id="app.leads.description" />,
      dataIndex: "description",
      key: "description",
      width: 150,
    },
    {
      title: <IntlMessages id="app.deals.startDate" />,
      width: 150,
      key: "startDate",
      dataIndex: "startDate",
      render: (startDate) => moment(startDate).format("DD-MM-YYYY"),
    },
    {
      title: <IntlMessages id="app.deals.nextPaymentDate" />,
      width: 150,
      key: "nextPaymentDate",
      dataIndex: "nextPaymentDate",
      render: (nextPaymentDate) => moment(nextPaymentDate).format("DD-MM-YYYY"),
    },
    {
      title: <IntlMessages id="app.deals.revenue" />,
      width: 150,
      key: "revenue",
      dataIndex: "revenue",
      render: (value) => <Tag color="orange">{convertDollarString(value)}</Tag>,
    },
    {
      title: <IntlMessages id="app.deals.cost" />,
      width: 150,
      key: "cost",
      dataIndex: "cost",
      render: (value) => <Tag color="pink">{convertDollarString(value)}</Tag>,
    },
    {
      title: <IntlMessages id="app.deals.profit" />,
      width: 150,
      key: "profit",
      dataIndex: "profit",
      render: (value) => <Tag color="green">{convertDollarString(value)}</Tag>,
    },
    ...UserActionColumns,
    {
      key: TABLE_ACTION_COLUMN_KEY,
      fixed: "right",
      title: <IntlMessages id="common.action" />,
      render: (_, record) => (
        <DealColumnActionOption id={record?.id} status={record?.status} />
      ),
    },
  ];
};

export default useGetDealColumn;
