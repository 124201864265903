import { useRef } from "react";
import { Typography } from "antd";
import AppColumnActionOption from "../../../@richbeauty/core/AppColumnActionOption";
import IntlMessages from "../../../@richbeauty/utility/IntlMessages";
import AppCustomModalWrapper from "../../../@richbeauty/core/AppCustomModalWrapper";
import useAppConfirmModal from "../../../hooks/useAppConfirmModal";
import { revalidateDeals } from "../../../@richbeauty/utility/helper/cacheRevalidator";
import {
  DeleteOutlined,
  EditOutlined,
  PushpinOutlined,
} from "@ant-design/icons";
import { PERMISSIONS } from "shared/constants/AppEnums";
import useDeleteDeals from "hooks/apis/deals/useDeleteDeals";
import EditDealModal from "./CreateAndEditDealModal/EditDealModal";
import ViewDealNotesModal from "./ViewDealNotesModel";
import toast from "react-hot-toast";
const DealColumnActionOption = ({ id, status }) => {
  const isConverted = status === "converted";

  const updateDealModalRef = useRef();
  const viewDealNotesModalRef = useRef();
  const { trigger } = useDeleteDeals();
  const { contextHolder, openModal } = useAppConfirmModal();
  const handleDeleteDeal = async () => {
    openModal({
      title: <IntlMessages id="app.deals.deleteDeal" />,
      okText: <IntlMessages id="common.save" />,
      cancelText: <IntlMessages id="common.cancel" />,
      onOk: () =>
        trigger([id], {
          onSuccess: async (data) => {
            revalidateDeals();
            toast.success(data?.message);
          },
          onError: (error) => toast.error(error?.message),
        }),
    });
  };

  return (
    <>
      <AppColumnActionOption
        items={[
          {
            scopes: [PERMISSIONS.EDIT_DEAL],
            isDisabled: isConverted,
            label: (
              <div style={{ color: isConverted ? "#00000040" : "black" }}>
                <EditOutlined /> <IntlMessages id="app.deals.editDeal" />
              </div>
            ),
            onClick: () => {
              if (isConverted) return;
              updateDealModalRef.current?.openModal();
            },
          },

          {
            scopes: [PERMISSIONS.EDIT_DEAL],
            label: (
              <>
                <Typography.Text>
                  <PushpinOutlined /> <IntlMessages id="common.notes" />
                </Typography.Text>
              </>
            ),
            onClick: () => viewDealNotesModalRef.current?.openModal(),
          },
          {
            scopes: [PERMISSIONS.DELETE_DEAL],
            label: (
              <Typography.Text type="danger">
                <DeleteOutlined /> <IntlMessages id="app.deals.deleteDeal" />
              </Typography.Text>
            ),
            onClick: handleDeleteDeal,
          },
        ]}
      />
      <AppCustomModalWrapper ref={updateDealModalRef}>
        <EditDealModal id={id} />
      </AppCustomModalWrapper>
      <AppCustomModalWrapper ref={viewDealNotesModalRef}>
        <ViewDealNotesModal id={id} />
      </AppCustomModalWrapper>
      {contextHolder}
    </>
  );
};

export default DealColumnActionOption;
