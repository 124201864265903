import { forwardRef, useCallback, useMemo } from "react";
import { toast } from "react-hot-toast";
import AppFormModal from "../../../../@richbeauty/core/AppFormModal";
import IntlMessages from "../../../../@richbeauty/utility/IntlMessages";
import { useIntl } from "react-intl";
import { revalidateDeals } from "../../../../@richbeauty/utility/helper/cacheRevalidator";
import useUpdateDeal from "hooks/apis/deals/useUpdateDeal";
import useGetDeal from "hooks/apis/deals/useGetDeal";
import moment from "moment";
import useCreateOrEditDealFieldConfig from "./useCreateOrEditDealFieldConfig";

const EditDealModal = forwardRef(({ id }, ref) => {
  const { formatMessage } = useIntl();
  const { data: dataGetDeal } = useGetDeal(id);

  const { trigger, isMutating } = useUpdateDeal();
  const formattedData = useMemo(() => {
    return {
      ...dataGetDeal,
      plan: dataGetDeal?.plan?.type,
      startDate: moment(dataGetDeal?.startDate),
    };
  }, [dataGetDeal]);
  const createOrEditDealFieldConfig = useCreateOrEditDealFieldConfig({
    isEdit: true,
  });
  const onHandleEditCoupon = useCallback(
    (formData) => {
      trigger(
        { formData, id },
        {
          onSuccess: () => {
            toast.success(formatMessage({ id: "app.deals.editDeal.success" }));
            revalidateDeals(id);
            ref.current?.closeModal();
          },
          onError: (error) => toast.error(error?.message),
        }
      );
    },
    [trigger, id, ref, formatMessage]
  );
  return (
    <AppFormModal
      ref={ref}
      data={formattedData}
      title={<IntlMessages id="app.deals.editDeal" />}
      onFinish={onHandleEditCoupon}
      isSubmitting={isMutating}
      fieldsConfig={createOrEditDealFieldConfig}
    />
  );
});

export default EditDealModal;
