import jwtAxios from "@richbeauty/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@richbeauty/services/endpoint";
import useSWR from "swr";

const getAllCampaign = async (params) => {
  const response = await jwtAxios.get(URL_API.CMS_SEO_CAMPAIGNS, { params });
  return response.data;
};
const useGetAllCampaign = (params) => {
  const fetcher = () => getAllCampaign(params);
  return useSWR(
    { url: URL_API.CMS_SEO_CAMPAIGNS, tags: ["#campaigns"] },
    fetcher
  );
};

export default useGetAllCampaign;
