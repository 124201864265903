import { Avatar } from "antd";
import {
  convertDateTime,
  convertToS3Link,
  testImage,
} from "../../../utility/Utils";
import React, { useEffect, useMemo, useState } from "react";
import "./index.style.less";
import { S3Type } from "../../../../shared/constants/AppEnums";
import AnonymousUserImage from "../../../../assets/images/anonymous-user.jpeg";

const UserActionInfoItem = ({ time, username, id, s3Profile }) => {
  const [isAvatarLoadable, setIsAvatarLoadable] = useState(false);
  const isAnonymous = time && !username;
  const avatar = useMemo(
    () =>
      isAnonymous
        ? AnonymousUserImage
        : convertToS3Link(S3Type.UID_AVATAR, id, s3Profile),
    [id, isAnonymous, s3Profile]
  );
  useEffect(() => {
    testImage(avatar).then(
      () => setIsAvatarLoadable(true),
      () => setIsAvatarLoadable(false)
    );
  }, [avatar]);
  const formattedUsername = isAnonymous ? "Anonymous user" : username;

  return (
    <div className="user-action-info-wrapper">
      {isAvatarLoadable
        ? avatar && <Avatar className="cr-user-info-avatar" src={avatar} />
        : username && (
            <Avatar className="cr-user-info-avatar">
              {username.charAt(0).toUpperCase()}
            </Avatar>
          )}
      <div className="user-action-info-right-wrapper">
        {formattedUsername && <span>{formattedUsername}</span>}
        {time && (
          <span className="user-action-info-time-item">
            {convertDateTime(time)}
          </span>
        )}
      </div>
    </div>
  );
};

export default UserActionInfoItem;
