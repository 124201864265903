import AppPageMetadata from "@richbeauty/core/AppPageMetadata";
import AppsContainer from "@richbeauty/core/AppsContainer";
import AppsContent from "@richbeauty/core/AppsContainer/AppsContent";
import AppsHeader from "@richbeauty/core/AppsContainer/AppsHeader";
import useAppShowHideTableColumn from "hooks/UseAppShowHideTableColumn";
import { useIntl } from "react-intl";
import "./index.style.less";
import AppButtonActions from "../../../@richbeauty/core/AppButtonAction";
import { useCallback, useEffect, useRef, useState } from "react";
import useAppConfirmModal from "hooks/useAppConfirmModal";
import IntlMessages from "../../../@richbeauty/utility/IntlMessages";
import { Input, Pagination } from "antd";
import { revalidateDeals } from "@richbeauty/utility/helper/cacheRevalidator";
import AppTableContainer from "@richbeauty/core/AppTableContainer";
import { PageDefault, TakeDefault } from "shared/constants/AppConst";
import useDebounce from "hooks/useDebounce";
import AppCustomModalWrapper from "@richbeauty/core/AppCustomModalWrapper";
import useGetDealColumn from "./useGetDealColumn";
import useGetDeals from "hooks/apis/deals/useGetDeals";
import useDeleteDeals from "hooks/apis/deals/useDeleteDeals";
import CreateDealModal from "./CreateAndEditDealModal/CreateDealModal";
import { PERMISSIONS } from "shared/constants/AppEnums";
import toast from "react-hot-toast";

const DEAL_TABLE_KEY = "DealTable";
const Deals = () => {
  const { formatMessage, messages } = useIntl();
  const [searchValue, setSearchValue] = useState("");
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const createDealModalRef = useRef();
  const dealColumns = useGetDealColumn();
  const { contextHolder: contextConfirmDelete, openModal: openConfirmDelete } =
    useAppConfirmModal();
  const { filteredTableColumns, tableColumnDisplayConfigurator } =
    useAppShowHideTableColumn({
      tableKey: DEAL_TABLE_KEY,
      tableColumns: dealColumns,
    });
  const { trigger: triggerDeleteDeals } = useDeleteDeals();
  const onDelete = useCallback(() => {
    openConfirmDelete({
      title: <IntlMessages id="app.quotes.deleteQuote" />,
      okText: <IntlMessages id="common.save" />,
      cancelText: <IntlMessages id="common.cancel" />,
      onOk: () => {
        triggerDeleteDeals(selectedRowIds, {
          onSuccess: async (data) => {
            revalidateDeals();
            setSelectedRowIds([]);
            toast.success(data?.message);
          },
          onError: (error) => toast.error(error?.message),
        });
      },
    });
  }, [openConfirmDelete, selectedRowIds, triggerDeleteDeals]);
  const [params, setParams] = useState({
    page: PageDefault,
    take: TakeDefault,
    freeText: "",
  });
  const { data: dealData, isLoading: isLoadingDealsData } = useGetDeals(params);

  const { debouncedVal } = useDebounce(searchValue);
  useEffect(() => {
    setParams((prev) => ({
      ...prev,
      freeText: debouncedVal,
      page: PageDefault,
    }));
  }, [debouncedVal]);
  return (
    <>
      <AppPageMetadata title={formatMessage({ id: "sidebar.crm.deals" })} />
      <AppsContainer
        fullView
        type="bottom"
        title={formatMessage({ id: "sidebar.crm.deals" })}
        headerRightElement={tableColumnDisplayConfigurator}
      >
        <AppsHeader>
          <div className="page-header">
            <div className="page-header-input-view">
              <Input
                type="search"
                id="quote-name"
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder={formatMessage({ id: "common.searchHere" })}
              />
            </div>
            <div className="page-header-right">
              <AppButtonActions
                scopes={[PERMISSIONS.CREATE_DEAL]}
                title={messages["app.deals.createDeal"]}
                type="primary"
                onClick={() => createDealModalRef.current?.openModal()}
              />
              <AppButtonActions
                scopes={[PERMISSIONS.DELETE_DEAL]}
                title={formatMessage({ id: "app.deals.deleteDeal" })}
                disabled={!selectedRowIds.length}
                onClick={onDelete}
                type="danger"
              />
            </div>
          </div>
        </AppsHeader>
        <AppsContent>
          <AppTableContainer
            data={dealData?.data}
            columns={filteredTableColumns}
            rowSelection={{
              type: "checkbox",
              onChange: (_, selectedRows) =>
                setSelectedRowIds(selectedRows?.map((row) => row?.id)),
            }}
            loading={isLoadingDealsData}
          />
        </AppsContent>
        <div className="custom-pagination">
          <Pagination
            current={params.page}
            onChange={(page, pageSize) =>
              setParams((prev) => ({ ...prev, page: page, take: pageSize }))
            }
            total={dealData?.meta?.totalCount}
            showTotal={(total, range) => (
              <p>
                {range[0]}-{range[1]} {String(messages["common.on"])} {total}{" "}
                records
              </p>
            )}
            showSizeChanger
          />
        </div>
        <AppCustomModalWrapper ref={createDealModalRef}>
          <CreateDealModal />
        </AppCustomModalWrapper>
      </AppsContainer>
      {contextConfirmDelete}
    </>
  );
};

export default Deals;
