import jwtAxios from "@richbeauty/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@richbeauty/services/endpoint";
import useSWR from "swr";
const getDeals = async (params) => jwtAxios.get(URL_API.CMS_DEALS, { params });

const useGetDeals = (params) => {
  const fetcher = () => getDeals(params);
  return useSWR({ url: URL_API.CMS_DEALS, params, tags: ["#deals"] }, fetcher);
};

export default useGetDeals;
