import jwtAxios from "@richbeauty/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@richbeauty/services/endpoint";
import useSWR from "swr";
const getDeal = async (id) => {
  return await jwtAxios.get(`${URL_API.CMS_DEALS}/${id}`);
};
const useGetDeal = (id) => {
  const key = {
    id,
    url: URL_API.CMS_DEALS,
    tags: [`#dealId:${id}`],
  };
  const fetcher = () => getDeal(id);
  return useSWR(key, fetcher);
};
export default useGetDeal;
