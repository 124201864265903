// @ts-nocheck
import React from "react";
import { PERMISSIONS } from "shared/constants/AppEnums";

const Roles = React.lazy(() => import("./Roles"));
const Settings = React.lazy(() => import("./Settings"));

export const systemConfig = [
  {
    path: "/systems/roles",
    element: <Roles />,
    scopes: [
      PERMISSIONS.CREATE_ROLE,
      PERMISSIONS.EDIT_ROLE,
      PERMISSIONS.DELETE_ROLE,
    ],
  },
  {
    path: "/systems/settings",
    element: <Settings />,
    scopes: [
      PERMISSIONS.CREATE_ROLE,
      PERMISSIONS.EDIT_ROLE,
      PERMISSIONS.DELETE_ROLE,
    ],
  },
];
