// @ts-nocheck
import React from "react";

const Blogs = React.lazy(() => import("./Blogs"));
const Tags = React.lazy(() => import("./Tags"));
const Categories = React.lazy(() => import("./Categories"));
const ShippingRanges = React.lazy(() => import("./ShippingRanges"));
const Banners = React.lazy(() => import("./Banners"));
const Products = React.lazy(() => import("./Products"));
const Reviews = React.lazy(() => import("./Reviews"));
export const cmsConfig = [
  {
    path: "/cms/blogs",
    element: <Blogs />,
  },
  {
    path: "/cms/tags",
    element: <Tags />,
  },
  {
    path: "/cms/categories",
    element: <Categories />,
  },
  {
    path: "/cms/shipping-ranges",
    element: <ShippingRanges />,
  },
  {
    path: "/cms/banners",
    element: <Banners />,
  },
  {
    path: "/cms/products",
    element: <Products />,
  },
  {
    path: "/cms/reviews",
    element: <Reviews />,
  },
];
